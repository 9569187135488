import React from 'react';
import { useThemeUI, ThemeProvider as ThemeProvider$1, jsx as jsx$1, __themeUiDefaultContextValue } from '@theme-ui/core';
import { css } from '@theme-ui/css';
import { ColorModeProvider } from '@theme-ui/color-modes';
import { Global } from '@emotion/react';
import { jsx, jsxs } from '@theme-ui/core/jsx-runtime';

const RootStyles = () => jsx$1(Global, {
  styles: emotionTheme => {
    var _theme$config;
    const theme = emotionTheme;
    const {
      useRootStyles
    } = theme.config || theme;
    if (useRootStyles === false || theme.styles && !theme.styles.root) {
      return null;
    }
    const boxSizing = ((_theme$config = theme.config) == null ? void 0 : _theme$config.useBorderBox) === false ? undefined : 'border-box';
    return css({
      '*': {
        boxSizing
      },
      html: {
        variant: 'styles.root'
      },
      body: {
        margin: 0
      }
    })(theme);
  }
});
const ThemeUIProvider = ({
  theme,
  children
}) => {
  const outer = useThemeUI();
  const isTopLevel = outer === __themeUiDefaultContextValue;
  return jsx(ThemeProvider$1, {
    theme: theme,
    children: jsxs(ColorModeProvider, {
      children: [isTopLevel && jsx(RootStyles, {}), children]
    })
  });
};

/** @deprecated ThemeProvider is now called ThemeUIProvider to reduce confusion with Emotion */
const ThemeProvider = ({
  theme,
  children
}) => {
  React.useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.warn('[theme-ui] The export ThemeUIProvider is deprecated and is now called ThemeProvider to reduce confusion with Emotion. Please update your import; ThemeUIProvider will be removed in a future version.');
    }
  }, []);
  const outer = useThemeUI();
  const isTopLevel = outer === __themeUiDefaultContextValue;
  return jsx(ThemeProvider$1, {
    theme: theme,
    children: jsxs(ColorModeProvider, {
      children: [isTopLevel && jsx(RootStyles, {}), children]
    })
  });
};

export { ThemeProvider, ThemeUIProvider };
