"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseEnumDef = void 0;
function parseEnumDef(def) {
    return {
        type: "string",
        enum: def.values,
    };
}
exports.parseEnumDef = parseEnumDef;
